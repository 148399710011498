import React from 'react'
import classnames from 'classnames'
import styles from './CtaButton.module.sass'

const CtaButton = ({ Component = 'button', children, className, ...props }) => {
  const cl = classnames(
    {
      [styles.wrap]: true,
    },
    className
  )
  return (
    <Component className={cl} {...props}>
      {children}
    </Component>
  )
}

export default CtaButton
