import React from 'react'
import classnames from 'classnames'
import styles from './Section.module.sass'

const Section = ({
  addPadding,
  alternative,
  className,
  children,
  ...props
}) => {
  const cl = classnames(
    {
      [styles.section]: true,
      [styles.padding]: addPadding,
      [styles.alternative]: alternative,
    },
    className
  )
  return (
    <section className={cl} {...props}>
      {children}
    </section>
  )
}

export default Section
