import React from 'react'
import { Trans } from '@lingui/macro'
import CtaButton from './CtaButton.js'

const calendlyUrl = 'https://calendly.com/remente/remente-b2b-demo'

const BookDemoCtaButton = props => {
  const handleBookDemo = e => {
    if (!window.Calendly) return
    e.preventDefault()
    window.Calendly.showPopupWidget(calendlyUrl)
  }

  return (
    <CtaButton
      Component="a"
      href={calendlyUrl}
      onClick={handleBookDemo}
      {...props}
    >
      <Trans>Book a demo</Trans>
    </CtaButton>
  )
}

export default BookDemoCtaButton
