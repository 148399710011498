import { useState, useEffect } from 'react'

const useWindowScroll = () => {
  const [isTicking, setIsTicking] = useState()
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  function handleScroll() {
    if (isTicking) return false
    window.requestAnimationFrame(() => {
      setIsTicking(false)
      setPos({
        x: window.scrollX,
        y: window.scrollY,
      })
    })
    setIsTicking(true)
  }

  return pos
}

export default useWindowScroll
