import React from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import Link from 'gatsby-link'
import styles from './TopBar.module.sass'
import logo from '../graphics/remente-logo.svg'
import Section from './Section.js'
import Container from './Container.js'
import BookDemoCtaButton from './BookDemoCtaButton.js'
import LanguageSelector from './LanguageSelector.js'
import useWindowScroll from '../hooks/useWindowScroll.js'

const primaryNavLinks = [
  { title: <Trans>Features</Trans>, path: '#keys-to-success' },
  { title: <Trans>Blog</Trans>, path: 'https://blog.remente.com' },
]

const TopBar = ({ langs }) => {
  const scrollPos = useWindowScroll()
  const cl = classnames({
    [styles.section]: true,
    [styles.scrolled]: scrollPos.y > 0,
    [styles.scrolledCta]: scrollPos.y > 450,
  })

  return (
    <Section className={cl}>
      <Container>
        <div className={styles.bar}>
          <nav>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="Remente" />
            </Link>
            <ul className={styles.hideMobile}>
              {primaryNavLinks.map(NavLink)}
            </ul>
          </nav>
          <nav className={styles.ctaMenu}>
            <ul>
              <li className={styles.hideMobile}>
                <a href="https://app.remente.com/login">
                  <Trans>Log in</Trans>
                </a>
              </li>
              <li className={styles.flags}>
                <LanguageSelector langs={langs} verbose={false} />
              </li>
              <li>
                <BookDemoCtaButton className={styles.cta} />
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </Section>
  )
}

const NavLink = ({ path, title }) => (
  <li key={path}>
    <a href={path}>{title}</a>
  </li>
)

export default TopBar
