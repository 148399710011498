import React from 'react'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { StaticQuery, graphql } from 'gatsby'
import { I18nProvider } from '@lingui/react'
import 'normalize.css'
import TopBar from '../components/TopBar.js'
import Footer from '../components/Footer.js'
import CookiesBanner from '../components/CookiesBanner.js'
import en from '../locales/en/messages.js'
import sv from '../locales/sv/messages.js'
import './index.sass'

const isProduction = process.env.NODE_ENV === 'production'

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/')
        const langMenuItems = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, '/'),
        }))
        const catalogs = { sv, en }
        return (
          <I18nProvider language={langKey} catalogs={catalogs}>
            <React.Fragment>
              <Helmet>
                <meta
                  name="image"
                  content="https://business.remente.com/remente.jpg"
                />
                <meta
                  property="og:url"
                  content="https://business.remente.com"
                />
                <meta property="og:type" content="website" />
                <link
                  href="https://assets.calendly.com/assets/external/widget.css"
                  rel="stylesheet"
                />
                <script
                  src="https://assets.calendly.com/assets/external/widget.js"
                  type="text/javascript"
                />
                <script type="text/javascript">{isProduction && `
                  !function() {
                    var t = window.driftt = window.drift = window.driftt || [];
                    if (!t.init) {
                      if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                      t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
                      t.factory = function(e) {
                        return function() {
                          var n = Array.prototype.slice.call(arguments);
                          return n.unshift(e), t.push(n), t;
                        };
                      }, t.methods.forEach(function(e) {
                        t[e] = t.factory(e);
                      }), t.load = function(t) {
                        var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                        o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                        var i = document.getElementsByTagName("script")[0];
                        i.parentNode.insertBefore(o, i);
                      };
                    }
                  }();
                  drift.SNIPPET_VERSION = '0.3.1';
                  drift.config({ locale: '${langKey}' });
                  drift.load('c2r3mw24aiiw');
                `}</script>
              </Helmet>
              <TopBar langs={langMenuItems} />
              {children}
              <Footer langs={langMenuItems} />
              <CookiesBanner />
            </React.Fragment>
          </I18nProvider>
        )
      }}
    />
  )
}

export default Layout
