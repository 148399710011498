module.exports = {
  langs: ['en', 'sv'],
  defaultLangKey: 'en',
  byKey: {
    en: {
      name: 'English',
      desc: 'View this page in English',
      icon: '🇺🇸',
    },
    sv: {
      name: 'Svenska',
      desc: 'Visa den här sidan på Svenska',
      icon: '🇸🇪',
    },
  },
}
