import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import styles from './CookiesBanner.module.sass'

const CookiesBanner = () => {
  const [isCookiesAccepted, setIsCookiesAccepted] = useState(true)

  useEffect(() => {
    const ls = window.localStorage.cookieOk
    if (ls) return setIsCookiesAccepted(JSON.parse(ls))
    setIsCookiesAccepted(false)
  }, [])

  useEffect(() => {
    if (window) window.localStorage.cookieOk = JSON.stringify(isCookiesAccepted)
  }, [isCookiesAccepted])

  const handleOk = () => {
    setIsCookiesAccepted(true)
  }

  const cl = classnames({
    [styles.cookies]: true,
    [styles.show]: !isCookiesAccepted,
  })

  return (
    <div className={cl}>
      <div className={styles.inner}>
        <Trans>
          <p>
            We use{' '}
            <a
              href="https://cookiesandyou.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              cookies
            </a>{' '}
            to ensure you get the best experience.
          </p>
        </Trans>
        <button onClick={handleOk}>
          <Trans>Got it!</Trans>
        </button>
      </div>
    </div>
  )
}

export default CookiesBanner
