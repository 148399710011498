import React from 'react'
import Section from './Section.js'
import Container from './Container.js'
import LanguageSelector from './LanguageSelector.js'
import styles from './Footer.module.sass'

const year = new Date().getFullYear()

const Footer = ({ langs }) => {
  return (
    <Section addPadding className={styles.footer}>
      <Container>
        <span>Copyright © {year} Remente AB</span>
        <LanguageSelector langs={langs} />
      </Container>
    </Section>
  )
}

export default Footer
