import React from 'react'
import classnames from 'classnames'
import styles from './Container.module.sass'

const Container = ({ children, className, ...props }) => (
  <div className={classnames(styles.container, className)} {...props}>
    {children}
  </div>
)

export default Container
