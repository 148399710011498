import React from 'react'
import Link from 'gatsby-link'
import languages from '../data/languages.js'
import styles from './LanguageSelector.module.sass'

const LanguageSelector = ({ langs = [], verbose = true }) => {
  return (
    <div className={styles.wrap}>
      <ul className={styles.languages}>
        {langs
          .filter(({ selected }) => !selected)
          .map(props => (
            <Language key={props.langKey} {...props} verbose={verbose} />
          ))}
      </ul>
    </div>
  )
}

const Language = ({ langKey, link, verbose }) => {
  const { desc, icon } = languages.byKey[langKey]
  const body = verbose ? desc : icon
  return (
    <li className={styles.language}>
      <Link to={link} title={desc}>
        {body}
      </Link>
    </li>
  )
}

export default LanguageSelector
